@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0px;
  background-color: #fefefe;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
}
.content-container {
  background-color: #f9fbff;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.object-table-container {
  animation: fadein 2s;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.empty-grid-cell-inner {
  background-color: #e63946;
  opacity: 95%;
  margin: 0 0.5px;
  border-radius: 2px;
}

.empty-data-message-container {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
}

.empty-data-message > p {
  display: inline;
  color: #293241;
}

.empty-data-message {
  position: relative;
  font-size: 24px;
  top: 23%;
}

.fade-in {
  animation: fadein 3s;
}

#no-grid-data-icon {
  position: relative;
  font-size: 50px;
  top: 1.5%;
  right: 1.5%;
  color: #e63946;
}

.header-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 20vh;
  border-bottom: 0.95px solid #c1d3de;
}

#loading-wheel {
  position: absolute;
  right: 2%;
  top: 38%;
}

#show-grid-button {
  padding: 5px 25px;
  background-color: #e63946;
  border-radius: 3.5px;
  color: white;
  font-size: 1.2rem;
}

#show-grid-button:hover {
  background-color: #bf303b;
  cursor: pointer;
}

#show-grid-button:disabled {
  background-color: #a82832;
  opacity: 70%;
}
#location-select {
  padding: 7px 30px 3px 10px;
  font-size: 1.2rem;
  font-weight: lighter;
  border-radius: 4px;
}

img {
  width: 100%;
  height: 80%;
  margin-bottom: 0px;
}
.standard-img:hover {
  cursor: pointer;
}
.MuiModal-root {
  position: relative;
  left: 30px;
}
.time-icon {
  position: relative;
}

.image-and-time-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #a0a096;
  border-radius: 4px;
  width: 98%;
  height: 500px;
  background-color: white;
}

.grid-cell-timestamp {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-decoration: underline;
  position: relative;
  top: 2px;
  color: #242423;
}
/* copied then edited from 'react-calendar/dist/Calendar.css';*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fdfdfd;
  border: 0.75px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 2px 0px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  min-width: 0;
  height: 14px;
  margin-bottom: 3px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.4em;
  color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
}
.react-calendar__tile {
  max-width: 100%;
  padding: 5px 3.33335px;

  background: none;
  text-align: center;
  line-height: 8px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #a8dadc;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--active {
  background: #1d3557;
  color: white;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1d3557;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media screen and (max-height: 650px) {
  .react-calendar {
  }
  .react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 4px;
    font-size: 10px;
  }
  .react-calendar__navigation__label {
    font-size: 10px;
    position: relative;
    top: 4px;
    width: 0px;
    white-space: nowrap;
  }
}
@media screen and (max-width: 800px) {
  .react-calendar {
    width: 30vw;
  }

  .react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 6px;
  }
  .react-calendar__navigation button {
    min-width: 10px;
  }

  .react-calendar__navigation__label {
    font-size: 11px;
    position: relative;
    top: 2px;
    width: 0px;
    white-space: nowrap;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1d3557;
  }
}
